<template>
  <div>
    <SubNav :title="title" />
    <div class="container-fluid pb-1">
      <div class="mb-sm-4 mx-3 my-4 row">
        <div class="col-lg-12">
          <div class="border">
            <div class="row p-3">
              <div class="col">
                <h5 class="h5 text-muted font-weight-bold">List of all active members</h5>
                <p>View, edit, invite and delete.</p>
              </div>
              <div>
                <AppLink to="/users/invite">
                  <button class="btn mr-2 float-right btn-action btn-primary shadow">
                    Invite new member
                  </button>
                </AppLink>
              </div>
            </div>

            <div class="row p-3">
              <div class="col-lg-3 mb-1">
                <span>Showing all {{ items.length }} records</span>
              </div>
              <div class="col-lg-6">
                <b-form>
                  <b-form-input placeholder="Search" type="search"></b-form-input>
                </b-form>
              </div>
              <div class="col-lg-3">
                <button class="btn mr-2 float-right btn-action btn-warning">
                  Delete
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </button>
              </div>
            </div>

            <TableComponent
              :loading="loadingList"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :fields="fields"
              :data="items"
              @selected="handleSelection"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableComponent from "../../components/Table.vue";
import AppLink from "../../components/layout/components/Sidebar/Link.vue";
import SubNav from "../../components/SubNav.vue";

import { mapGetters } from "vuex";

export default {
  name: "ListUsers",
  components: {
    TableComponent,
    AppLink,
    SubNav,
  },
  data() {
    return {
      sortBy: "role",
      sortDesc: false,
      loadingList: false,
      selectedUsers: [],
    };
  },
  computed: {
    ...mapGetters(["users"]),
    items() {
      let users = this.users.map((user) => {
        return {
          name: `${user.last_name} ${user.first_name}`,
          email: user.email,
          role: "Admin",
        };
      });
      return users;
    },
    fields() {
      return [
        { key: "name", label: "Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "role", label: "Role", sortable: true },
      ];
    },
    title() {
      return "Members";
    },
  },
  created() {
    this.getUsers();
  },
  methods: {
    /**
     *
     */
    handleSelection(items) {
      console.log(items);
    },
    /**
     *
     */
    getUsers() {
      this.loadingList = true;
      this.$store
        .dispatch("users/loadusers")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
